import React from 'react'
import Layout from '../Components/Layout'
import Svglaptop from '../Components/SvgLaptop'
import CodeSVG from '../Components/CodeSVG'
import UnboSVG from '../Components/UnboSVG'
import Link from '../Components/Link'


export default function Homepage() {
    return (
        <div className='webpage'>
        <Layout>
      <section className='header'>
      <div className='headerplacement'></div>
          <div className='headerContent'>
        <div className='leftContent'>
          <h2>Design</h2>
          <h3>Develop & Deploy</h3>
          <p>We build your apps so you can do great business </p>
        </div>
        <div className='rightContent'>
        <Svglaptop />
        </div>
</div>
<div className='btmhead'></div>
        <div class="custom-shape-divider-bottom-1638699797">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
</div>
      </section >
      <section className='midsection'>
        <div className='sectionone'>
<h4>We help build simple to complicated solutions from no code to naitive applications</h4> 
        <div className='midcontent'>
        <div className='cards a'>
           <div className='circle_container'><div className='circle'>
        <CodeSVG />
           </div>
           </div>
          Code<div className='content'></div><div className='card_content'>
            <span>We can offer fully coded app solutions for you</span>
          </div></div>
          <div className='cards b'>
          <div className='circle_container'><div className='circle'>
            <UnboSVG />
            </div></div>
            Low code Platforms<div className='content'><div className='card_content'>
            <span>We work with many partners in the no/low code marketplace. This 
is mainly desireable for solutions that need to be build quickly.</span>
          </div>
              </div></div>
          <div className='cards c'>
          <div className='circle_container'><div className='circle'>
            <Link />
            </div></div>
             Intergrations<div className='content'></div><div className='card_content'>
            <span>We help connect your apps and services to intergrate with others.</span>
          </div></div>
        </div>
        
        
    </div>
      </section>

    </Layout>
        </div>
    )
}
