
import './App.css';
import './iphone.css'
import Homepage from './Pages/Homepage'
import { Helmet } from "react-helmet";



function App() {

  return (

    <div className="App">
      <Helmet>
    <meta charSet="utf-8" />
    <title>Appknowledged</title>
    <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Homepage />
    </div>
  );
}

export default App;
