import React from "react";
import "../App.css";

function Modal({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h4>Send us a message</h4>
        </div>
        <div className="body">
        <form className="emailForm" action="https://formsubmit.co/johnny@appknowleged.co.uk" method="POST">
        <input type="email" name="email" placeholder="Email Address"></input>
     <input placeholder="Name" type="text" name="name" required></input>
     <input className="messageInput" placeholder="Message" type="text" name="text" required></input>
    <div className="footer">
     <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
     <button type="submit">Send</button>
     </div>
         </form>
        </div>
      </div>
    </div>
  );
}

export default Modal;
