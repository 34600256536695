import React from 'react'


export default function Navbar() {
  return (
    <nav>
      <h1>Appknowledged</h1>
      <div className="links">
        <button>Log In</button>
      </div>
    </nav>
  )
}
